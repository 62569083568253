h1 {
  cursor: pointer;
}

.centered-text {
  text-align: center;
  margin: 4px;
}

.feedback-button {
  width: 100%;
  background-color: #4caf50;
}

.feedback-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background-color: #4caf50;
  border-radius: 12px;
  color: white;
}

.feedback-container button {
  width: 75%;
  background-color: #c0d91e;
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  margin: 4px;
}

.feedback-container p {
  margin: 1px;
  padding: 4px;
  text-align: center;
}

/* The modifier for hoven on the feeback button */
.feedback-button:hover {
  background-color: #46a049;
}

.file-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px dashed #171818;
  height: 100%;
  width: 100%;
  max-width: 500px;
  max-height: 500px;
  min-height: 400px;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.file-input-label {
  display: flex;
  flex-direction: column;  /* This makes the children stack vertically */
  justify-content: center;  /* This centers children vertically */
  align-items: center;  /* This centers children horizontally */
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  background-color: #f2f2f2;
  border-radius: 15px;
  padding: 3px;
  width: 100%;
  max-width: 1000px;
  justify-items: center;
  padding: 15px;
  margin-bottom: 15px;
}

.image-uploader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 20px;
  gap: 10px;
}

.image-uploader-container button {
  padding: 10px 20px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 1px;
}

.image-pane {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  width: 99%;
}

.row-centered {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
}
.results-pane {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 500px;
}

.results-pane h3 {
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
  font-size: 22px;
}

.results-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}

.result-category {
  background-color: black;
  color: white;
  border-radius: 10px;
  min-width: 200px;
  padding: 7px;
  margin: 4px;
}

.result-category h4 {
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 20px;
}

.results-pane li {
  margin-bottom: 7px;  /* Adjust as needed */
  font-size: 18px;      /* Adjust as needed */
}

.results-pane ul {
  margin-top: 5px;
  margin-left: 15px;
  padding: 7px;
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  min-height: 100px;
  width: 100%;
  background-color: black;
  padding-top: 32px;
  border-radius: 12px;
  margin: 4px;
}

.prediction-progress {
  height: 10px;
  background-color: #4caf50;
  width: 0%; /* Default width, will be adjusted dynamically */
}

.selected-image {
  max-width: 99%;  /* it can take up to 99% of its parent's width */
  max-height: 99%;  /* it can take up to 99% of its parent's height */
  object-fit: contain;  /* scales the image as large as possible within the bounding box without cropping or stretching it */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.share-button {
  /* Make it blue and like the submit button */
  background-color: #4caf50;
  color: white;
  border: solid 3px #4caf50;
  border-radius: 12px;
  cursor: pointer;
  margin: 4px;
  width: 100%;
}

.submit-button-main {
  width: 100%;
  height: 100px;
  background-color: red;
  font-size: 22px;
  border: none;
}

.submit-button:hover {
  background-color: #e60000;
}

.white-text {
  color: white;
}

/* Id's */
#resubmit-button {
  background-color: black;
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .file-input-container {
    width: 99%;
    height: 90%;
    min-height: 300px;
  }

  .grid-container {
    grid-template-columns: 1fr;
    width: 100%; /* Adjust the width based on the screen size */
    padding: 10px;
    padding-bottom: 15px;
  }

  .image-uploader-container {
    width: 90%;
    padding-top: 0;
  }

  .results-container {
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .results-pane {
    justify-content: flex-start;
    align-items: center;
    min-height: 100px;
  }

  .selected-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  
}
