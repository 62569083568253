.header {
    width: 100%;
    background-color: black;
    color: white; /* To make the text visible on a black background */
    padding: 10px 0; /* Add some vertical padding */
    text-align: center; /* Center the header text */
    height: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
  