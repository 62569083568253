.black-text {
    color: black;
}

.close-button {
    background-color: black;
    width: 40%;
}

.feedback-component-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
    background-color: #4caf50;
    border-radius: 12px;
    color: white;
    width: 80%;
}
  
.feedback-component-container button {
    width: 75%;
    background-color: #c0d91e;
    color: white;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    margin: 4px;
}

.feedback-component-container p {
    margin: 1px;
    padding: 4px;
    text-align: center;
}

.register-button {
    width: 40%;
    background-color: #4caf50;
}

/* Id's */
#feedback-input {
    width: 80%;
    text-align: center;
    border-radius: 12px;
    padding: 10px;
    border: none;
    margin-bottom: 7px;
}

#thankyou-h2 {
    color: black;
    text-align: center;
}
