.footer {
  width: 100%;
  background-color: black;
  color: white;
  padding: 10px 0;
  text-align: center;
}

.footer a {
  color: yellow;
}