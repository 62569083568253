.email-container {
    margin: 0 auto;
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
}

.email-container input {
    width: 100%;
    padding: 10px;
    margin: 10px;
    border-radius: 12px;
    border: 1px solid #ccc;
    text-align: center;
}

.email-submit-button {
    width: 100%;
    background-color: green;
    border: none;
    border-radius: 12px;
    padding: 10px;
    color: white;
    margin: 10px;
}

.feedback-container-page {
    margin: 0 auto;
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
}

.feedback-container-page p {
    margin: 4px;
}

.feedback-container-page input {
    width: 100%;
    padding: 10px;
    margin: 10px;
    border-radius: 12px;
    border: 1px solid #ccc;
    text-align: center;
}

.feedback-container-page textarea {
    width: 100%;
    padding: 10px;
    margin: 10px;
    border-radius: 12px;
    border: 1px solid #ccc;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
}

.submit-button {
    width: 100%;
    background-color: red;
    border: none;
    border-radius: 12px;
    padding: 10px;
    color: white;
    margin: 10px;
}
  
.submit-button:hover {
    background-color: #e60000;
}