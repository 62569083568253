.share-button-container {
  background-color: black;
  padding: 10px;
  display: flex;
  justify-content: space-around;
  width: 95%;
  border-radius: 12px;
}

.share-button-whatsapp, .share-button-twitter {
  padding: 12px 20px;
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.share-button-logo {
  margin-right: 8px;
}

.share-button-whatsapp {
  background-color: #25D366;  /* WhatsApp green */
}

.share-button-twitter {
  background-color: #1DA1F2;  /* Twitter blue */
}

/* WhatsApp Animation */
@keyframes scintillate-whatsapp {
  0%   { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.share-button-whatsapp.scintillate {
  background: linear-gradient(90deg, #25D366, #1a9e2e, #25D366);
  background-size: 300% 100%;
  animation: scintillate-whatsapp 5s ease infinite;
}

/* Twitter Animation */
@keyframes scintillate-twitter {
  0%   { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.share-button-twitter.scintillate {
  background: linear-gradient(90deg, #1DA1F2, #167ab6, #1DA1F2);
  background-size: 300% 100%;
  animation: scintillate-twitter 5s ease infinite;
}



