.feedback-container-page2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 300px;
    padding: 10px;
  }
  
.compact-form {
    text-align: center;
    width: 95%;
    margin: 0 auto;
}

.form-group {
    margin-bottom: 1rem;
    width: 100%;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
}

.form-group input,
.form-group textarea {
    width: 80%;
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    text-align: center;
    transition: border-color 0.3s ease;
    font-family: 'Roboto', sans-serif;
}

.form-group input::placeholder,
.form-group textarea::placeholder {
    color: grey;
    text-align: center;
}

.form-group input:focus,
.form-group textarea:focus {
    border-color: #4caf50;
}

.success-message-div {
    padding: 10px;
}
  
#feedback-page-2-button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: white;
    background-color: #4caf50;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
  
#feedback-page-2-button:hover {
    background-color: #429645;
}
  