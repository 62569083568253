.result-component {
    background-color: black;
    color: white;
    border-radius: 10px;
    min-width: 200px;
    padding: 7px;
    margin: 4px;
}

.result-component h4{
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 20px;
}

.result-component p {
    max-width: 150px;
    min-width: 150px;
    text-align: center;
}